import React from "react"

import Layout from "../components/0_utilities/layout"
import SEO from "../components/0_utilities/seo"

import HomeImg from "../components/1_atoms/banner/img/img-home"

const IndexPage = () => (
  <Layout isHome mainClass="main-home">
    <SEO
      title="Home"
      description="Piano Tuning and repair serving the Philadelphia and South Jersey areas"
    />
    <HomeImg />
    <div className="home">
      <div className="home-desc">
        <a href="/contact">Schedule now!</a>
      </div>
    </div>
  </Layout>
)

export default IndexPage
